<template>
  <div class="result-panel">
    <CSTable :thead-top="filterHeight">
      <template v-slot:header>
        <div class="table-header-panel" style="text-align: right">
          <button
            type="button"
            class="btn btn-primary"
            style="position: sticky;right: 125px;"
            @click="openSubscription()"
          >
            开启订阅
          </button>
          <button class="btn btn-primary sticky-right" @click="openAddBox">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-menujiahao"></use>
            </svg>
            AI盒子
          </button>
        </div>
      </template>
      <template v-slot:thead>
        <tr>
          <th>添加时间</th>
          <th>盒子名称</th>
          <th>盒子ip</th>
          <th>盒子端口</th>
          <th>mqtt ip</th>
          <th>mqtt 端口</th>
          <th>mqtt 用户名</th>
          <th>mqtt 密码</th>
          <th>操作</th>
        </tr>
      </template>
      <template v-slot:tbody>
        <template v-if="boxList != null && boxList.length > 0">
          <tr v-for="box in boxList" :key="box.id">
            <td style="min-width: 144px">{{ box.createTime || "-" }}</td>
            <td>{{ box.name }}</td>
            <td>{{ box.boxIp }}</td>
            <td>{{ box.boxPort }}</td>
            <td>{{ box.tcpIp }}</td>
            <td>{{ box.tcpPort }}</td>
            <td>{{ box.tcpUserName }}</td>
            <td>{{ box.tcpPassword }}</td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="min-width: 76px; width: 100px">
                  <li>
                    <a style="width: 100%" @click="openUpdateBox(box)">修改</a>
                  </li>
                  <li>
                    <a style="width: 100%" @click="deleteBox(box.id)">删除</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </CSTable>
    <Pagination />
    <CSDialog
      dialogTitle="提示"
      :dialogVisible="deleteInfo.visible"
      dialogWidth="480px"
      dialog-header-class=" "
      dialog-header-icon="icon-menua-zu13"
      dialog-confirm-btn-text="确定"
      @onClose="deleteInfo.onCancel"
      @onConfirm="deleteInfo.onOk"
    >
      <div
        slot="dialog-content"
        style="
          padding: 20px 30px;
          font-size: 24px;
          color: #000;
          text-align: center;
        "
      >
        {{ deleteInfo.title }}
      </div>
    </CSDialog>
    <CSDialog
      :dialogTitle="`添加AI盒子`"
      :dialog-visible="addBoxVisible"
      dialog-width="670px"
      @onClose="addBoxVisible = false"
      @onConfirm="addBoxInfo"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">盒子名称</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.name"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">盒子ip</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.boxIp"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">盒子端口</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.boxPort"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt ip</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.tcpIp"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt 端口</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.tcpPort"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt 用户名</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.tcpUserName"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt 密码</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="addBox.tcpPassword"
              />
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <CSDialog
      :dialogTitle="`修改AI盒子`"
      :dialog-visible="updateBoxVisible"
      dialog-width="670px"
      @onClose="updateBoxVisible = false"
      @onConfirm="updateAiBox"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">盒子名称</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.name"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">盒子ip</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.boxIp"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">盒子端口</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.boxPort"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt ip</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.tcpIp"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt 端口</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.tcpPort"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt 用户名</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.tcpUserName"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">mqtt 密码</div>
            <div>
              <input
                type="text"
                style="
                  width: 397px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                placeholder="请输入"
                v-model="updateBox.tcpPassword"
              />
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>
<script>
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import {
  queryAiBox,
  addAiBox,
  deleteAiBox,
  updateAiBox,
  startBox,
} from "@/requestUrl";

export default {
  components: {
    CSTable,
    CSDialog,
    CSSelect,
    Pagination,
  },
  name: "boxManagement",
  data() {
    return {
      filterHeight: "",
      boxList: [],
      addBox: {
        id: "",
        regionCode: "",
        name: "",
        boxIp: "",
        boxPort: "",
        tcpIp: "",
        tcpPort: "",
        tcpUserName: "",
        tcpPassword: "",
      },
      addBoxVisible: false,
      deleteInfo: {
        visible: false,
        title: "确定要删除吗?",
        onCancel: () => {
          this.deleteInfo.visible = false;
        },
        onOk: () => {},
      },
      updateBoxVisible: false,
      updateBox: {
        id: "",
        regionCode: "",
        name: "",
        boxIp: "",
        boxPort: "",
        tcpIp: "",
        tcpPort: "",
        tcpUserName: "",
        tcpPassword: "",
      },
    };
  },
  created() {
    this.query();
    this.$vc.on(this.$route.path, "pagination_page", "event", this.query);
  },
  methods: {
    openAddBox() {
      this.addBoxVisible = true;
    },
    addBoxInfo() {
      this.addBox.regionCode = this.$vc.getCurrentRegion().code;
      this.$fly.post(addAiBox, this.addBox).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          this.addBoxVisible = false;
          this.$vc.toast("添加盒子成功");
          Object.keys(this.addBox).forEach((key) => (this.addBox[key] = ""));
          this.query();
        }
      });
    },
    deleteBox(id) {
      console.log(id);
      this.deleteInfo.visible = true;
      this.deleteInfo.onOk = () => {
        this.$fly.get(deleteAiBox, { id: id }).then((res) => {
          if (res.code !== 0) {
            return;
          } else {
            this.$vc.toast("删除盒子成功");
            this.query();
          }
        });
        this.deleteInfo.visible = false;
      };
    },
    openUpdateBox(box) {
      this.updateBox = box;
      this.updateBoxVisible = true;
    },
    updateAiBox() {
      this.updateBox.regionCode = this.$vc.getCurrentRegion().code;
      let box = { ...this.updateBox };
      this.$delete(box, "createTime");
      console.log(this.updateBox);
      this.$fly.post(updateAiBox, box).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          this.updateBoxVisible = false;
          this.$vc.toast("修改盒子成功");
          this.query();
        }
      });
    },
    query(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(queryAiBox, {
          regionCode: this.$vc.getCurrentRegion().code,
          pageNo,
          pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          } else {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo,
              });
            }
            this.boxList = res.data.datas ?? [];
            console.log(this.boxList);
          }
        });
    },
    openSubscription() {
      this.$fly
        .get(startBox, {
          regionCode: this.$vc.getCurrentRegion().code,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          } else {
            this.$vc.toast("订阅成功");
            this.query();
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-form {
  padding: 27px 30px;
  font-size: 24px;
  color: #000;

  &-item {
    &:not(:last-of-type) {
      margin-bottom: 23px;
    }

    &-label {
      width: 156px;
      height: 33px;
      text-align: right;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 33px;
    }

    & > div {
      display: inline-block;
      vertical-align: middle;

      input {
        &::placeholder {
          color: #999;
        }

        width: 140px;
        height: 40px;
        border-radius: 4px;
        padding: 0 5px;
        border: 1px solid #979797;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
  }
}
</style>
